<!--
 * @Author: jiang
 * @Date: 2021-07-18 14:40:50
 * @Description: 人员工作清单列表
-->
<template>
  <el-scrollbar
    y
    view-style="padding: 0 15px 15px;"
  >
    <div class="g-bg g-px-20">
      <div class="header">
        <div class="info-box">
          <span class="dept-name">{{ currentUserId ? currentDeptName : userDepartmentName }}</span>
          <span class="user-name">{{ currentUserName || userName }}</span>
        </div>
        <template v-if="!currentUserId">
          <el-button
            v-if="infoData.id"
            type="warning"
            @click="onUpdate"
          >编辑</el-button>
          <el-button
            type="primary"
            @click="onCreate"
          >新增</el-button>
        </template>
      </div>
      <div class="time-box">
        <select-week @change="onWeekChange"></select-week>
      </div>
      <div class="main-box">
        <div
          v-if="!infoData.id"
          class="g-empty"
        ></div>
        <el-form
          v-else
          label-position="top"
        >
          <el-form-item label="本周工作总结">
            <div v-html="infoData.week_summary"></div>
          </el-form-item>
          <el-form-item label="下周工作计划">
            <div v-html="infoData.next_week_plan"></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
    >
      <detail-view
        :title="infoData.id ? '修改' : '新增'"
        :data="formData"
        :submit-loading="submitLoading"
        @confirm="onConfirm"
      ></detail-view>
    </el-dialog>
  </el-scrollbar>
</template>

<script>
import { mapState } from 'vuex'
import SelectWeek from '@/components/select/week'
import DetailView from './components/work-detail'
import { getUserWork, createUserWork, updateUserWork } from '@/api/manager'

export default {
  components: {
    SelectWeek,
    DetailView,
  },
  data() {
    return {
      infoData: {},
      currentUserId: this.$route.query.userId,
      currentUserName: this.$route.query.userName,
      currentDeptName: this.$route.query.dept,
      time: {
        start: '',
        end: '',
      },
      dialogVisible: false,
      submitLoading: false,
      formData: {},
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userName: state => state.userInfo.name,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
  },
  watch: {
    time(val) {
      console.log(val)
    },
  },
  methods: {
    fetchData() {
      const id = this.currentUserId || this.userId
      const params = {
        start_date: this.time.start,
        end_date: this.time.end,
      }
      getUserWork(id, params).then(res => {
        if (Array.isArray(res)) {
          res = {}
        }
        this.infoData = res
      })
    },
    onWeekChange(time) {
      this.time.start = time.start_date
      this.time.end = time.end_date
      this.fetchData()
    },
    onCreate() {
      this.formData = {
        week_summary: '',
        next_week_plan: '',
      }
      this.dialogVisible = true
    },
    onUpdate() {
      this.formData = {
        id: this.infoData.id,
        week_summary: this.infoData.week_summary,
        next_week_plan: this.infoData.next_week_plan,
      }
      this.dialogVisible = true
    },
    onConfirm(data) {
      this.submitLoading = true
      if (data.id) {
        const params = {
          week_summary: data.week_summary,
          next_week_plan: data.next_week_plan,
        }
        updateUserWork(data.id, params)
          .then(() => {
            this.$message.success('修改成功')
            this.fetchData()
            this.dialogVisible = false
          })
          .finally(() => {
            this.submitLoading = false
          })
      } else {
        createUserWork(data)
          .then(() => {
            this.$message.success('新建成功')
            this.fetchData()
            this.dialogVisible = false
          })
          .finally(() => {
            this.submitLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #f1f1f1;

  .info-box {
    flex: 1;
    min-width: 0;
  }

  .dept-name {
    font-size: 24px;
    font-weight: bold;
    color: #2b2d42;
  }

  .user-name {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #747e8f;
  }
}

.time-box {
  padding: 10px 0;
}

.main-box {
  padding: 10px 0;
}
</style>
